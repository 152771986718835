import PropTypes from "prop-types"
import React, { Fragment } from "react"
import HorizontalRule from "src/main/Account/Trips/Sales/HorizontalRule"
import Sale from "src/main/Account/Trips/Sales/Sale"

import HeadingModalTitle from "src/components/Heading/HeadingModalTitle"

import { snakecaseToTitlecase } from "src/utils/string_helpers"

const SaleCollections = ({ salesCollections }) => {
  return (
    <div className="flex flex-col space-y-8">
      {salesCollections?.map((salesCollection, index) => (
        <div key={`${salesCollection.type}-${index}`} className="card p-0">
          <div className="p-6">
            <HeadingModalTitle>
              {snakecaseToTitlecase(salesCollection.type)}
            </HeadingModalTitle>
          </div>
          {salesCollection.items.map((item, index) => (
            <Fragment key={item.encodedId}>
              <Sale {...item} />
              {index < salesCollection.items.length - 1 && <HorizontalRule />}
            </Fragment>
          ))}
        </div>
      ))}
    </div>
  )
}

SaleCollections.propTypes = {
  salesCollections: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["scheduled", "past_due", "failed", "settled"])
        .isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf([
            "scheduled",
            "past_due",
            "failed",
            "settled",
            "refund",
            "processing_refund",
          ]).isRequired,
          encodedId: PropTypes.string,
          balance: PropTypes.number,
          marina: PropTypes.string,
          dueDate: PropTypes.string,
          settledAt: PropTypes.string,
          totalPaid: PropTypes.number,
          refundTotal: PropTypes.number,
        })
      ),
    })
  ),
}

export default SaleCollections
