/* eslint react-hooks/rules-of-hooks: 0 */

/* the linter is not a fan of conditional hooks and for good reason, however we will not be flipping the feature
 * flag on and off during usage, more likely one time per marina, and most likely all at once. afterwards we
 * will remove the condition and should remove this disabled rule. adding boater_tmp_fuel_discounts so this is found */
import classNames from "classnames"
import PropTypes from "prop-types"
import React, { createContext, useEffect } from "react"
import { FormProvider } from "react-hook-form"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import Layout from "src/main/PointOfSale/Layout"
import useServerCart from "src/main/PointOfSale/hooks/useServerCart"

import usePOSAccess, {
  marinaAccessProps,
} from "src/hooks/module_gate_hooks/use_pos_access"
import useWindowSize from "src/hooks/use_window_size"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import PointOfSale from "."
import Checkout from "./Checkout"
import useCart from "./hooks/useCart"
import { useSelectedCardReader } from "./hooks/useSelectedCardReader"
import { useSelectedSoldByUser } from "./hooks/useSelectedSoldByUser"
import { formatItemForCart } from "./utils"

export const POSContext = createContext()

const Root = ({
  layouts,
  cardReaders,
  receiptPrinters,
  canManagePOS,
  marinaUsers,
  enableBluetoothCardReader,
  tipItem,
  enableTips,
  marinaAccess,
  currentUserEncodedId,
  serverCartFeatureEnabled,
  serverCartId,
}) => {
  const marinaSlug = getCurrentMarinaSlug()

  const cart = serverCartFeatureEnabled
    ? useServerCart(serverCartId)
    : useCart()

  const [selectedSoldByUser, setSelectedSoldByUser] = useSelectedSoldByUser()
  const [selectedCardReader, setSelectedCardReader] = useSelectedCardReader()
  const { access } = usePOSAccess({ marinaSlug, initialData: marinaAccess })
  const { isLargeScreen } = useWindowSize()

  useEffect(() => {
    setSelectedSoldByUser(
      marinaUsers.find((mu) => mu.id === selectedSoldByUser?.id) ||
        marinaUsers[0]
    )
  }, [marinaUsers, selectedSoldByUser?.id, setSelectedSoldByUser])

  useEffect(() => {
    if (cardReaders.length > 0) {
      setSelectedCardReader(
        cardReaders.find((cr) => cr.id === selectedCardReader?.id) ||
          cardReaders[0]
      )
    } else {
      setSelectedCardReader({})
    }
  }, [cardReaders, selectedCardReader?.id, setSelectedCardReader])

  const router = createBrowserRouter(
    [
      {
        element: (
          <Layout
            serverCartFeatureEnabled={serverCartFeatureEnabled}
            serverCartId={cart.id}
          />
        ),
        children: [
          {
            index: true,
            element: <PointOfSale />,
          },
          {
            path: "checkout",
            element:
              cart.fields.length === 0 ? (
                <Navigate replace to={"/"} />
              ) : (
                <Checkout
                  noCardReadersConfigured={cardReaders.length === 0}
                  enableBluetoothCardReader={enableBluetoothCardReader}
                  enableTips={enableTips}
                />
              ),
          },
        ],
      },
    ],
    {
      basename: `/manage/${marinaSlug}/point_of_sale`,
    }
  )

  return (
    <POSContext.Provider
      value={{
        layouts,
        cart,
        receiptPrinters,
        canManagePOS,
        marinaUsers,
        cardReaders,
        currentUserEncodedId,
        tipItem: formatItemForCart(tipItem),
        marinaAccess: access,
        serverCartFeatureEnabled,
        cartId: cart.id,
      }}
    >
      <FormProvider {...cart.methods}>
        <div
          className={classNames({
            "h-[calc(100dvh-65px)] px-2": isLargeScreen,
            "mt-4 h-[calc(100dvh-160px)]": !isLargeScreen,
          })}
        >
          <RouterProvider router={router} />
        </div>
      </FormProvider>
    </POSContext.Provider>
  )
}

Root.propTypes = {
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  cardReaders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    })
  ).isRequired,
  receiptPrinters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      hasCashDrawer: PropTypes.bool.isRequired,
    })
  ).isRequired,
  canManagePOS: PropTypes.bool,
  marinaUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  enableBluetoothCardReader: PropTypes.bool.isRequired,
  tipItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  enableTips: PropTypes.bool.isRequired,
  marinaAccess: PropTypes.shape(marinaAccessProps).isRequired,
  currentUserEncodedId: PropTypes.string.isRequired,
  serverCartFeatureEnabled: PropTypes.bool.isRequired,
  serverCartId: PropTypes.string,
}

export default Root
