import React from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { CUSTOM_RATE_ID } from "../../../constants"

const RateTaxInput = () => {
  const {
    register,
    formState: { errors },
    watch,
    trigger,
  } = useFormContext()
  const [rateId] = watch(["rate.id"])

  return (
    <div>
      <Form.Label htmlFor="trw-rate-tax">Tax</Form.Label>
      <Form.IconTextField
        icon="%"
        position="right"
        id="trw-rate-tax"
        type="number"
        inputMode="numeric"
        disabled={rateId !== CUSTOM_RATE_ID}
        {...register("rate.tax_rate")}
        hasErrors={Boolean(errors?.rate?.tax_rate)}
        onBlur={() => trigger("rate.tax_rate")}
      />
      {errors?.rate?.tax_rate?.message && (
        <Form.Error>{errors.rate.tax_rate.message}</Form.Error>
      )}
    </div>
  )
}

export default RateTaxInput
