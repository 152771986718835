import React, { useContext, useEffect } from "react"
import { useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { WizardContext } from "../../WizardContextProvider"
import { NO_COUPON_CODE_CHOSEN_VALUE } from "../../constants"

const CouponCodeSelector = () => {
  const { couponCodes, promoCodesPath, isMonthlyBilling } =
    useContext(WizardContext)
  const { register, watch, setValue } = useFormContext()
  const [couponCodeId] = watch(["coupon_code_id"])
  const selectedCouponCode = couponCodes.find(
    (coupon) => coupon.id.toString() === couponCodeId
  )

  useEffect(() => {
    // if the coupon code is no longer valid (ie not present in couponCodes array),
    // reset the form value to "none"
    if (couponCodeId !== NO_COUPON_CODE_CHOSEN_VALUE && !selectedCouponCode) {
      setValue("coupon_code_id", NO_COUPON_CODE_CHOSEN_VALUE)
    }
  }, [selectedCouponCode, couponCodeId])

  const renderSelectedCouponCode = () => {
    if (selectedCouponCode) {
      return (
        <div
          key={selectedCouponCode.id}
          className="flex flex-row items-center justify-between space-x-2 px-1 py-2 has-[:hover]:bg-gray-100"
        >
          <span>{selectedCouponCode.code}</span>
          <button
            type="button"
            onClick={() =>
              setValue("coupon_code_id", NO_COUPON_CODE_CHOSEN_VALUE)
            }
            className="flex size-6 items-center justify-center rounded-full bg-transparent text-red-700 hover:bg-red-700 hover:text-white"
          >
            <i
              data-testid={`trw-remove-coupon-code-${selectedCouponCode.id}`}
              className="icon icon-sf-x text-xs"
            />
          </button>
        </div>
      )
    }
    return null
  }

  return (
    <div>
      <Form.Label htmlFor="trw-coupon-code">
        Coupon Code{" "}
        <a
          href={promoCodesPath}
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer no-underline"
        >
          (settings)
        </a>
      </Form.Label>
      <Form.Select
        id="trw-coupon-code"
        disabled={couponCodes.length === 0 || isMonthlyBilling}
        {...register("coupon_code_id")}
      >
        <option value={NO_COUPON_CODE_CHOSEN_VALUE}>None</option>
        {couponCodes.map((option) => (
          <option key={option.id} value={option.id}>
            {option.code}
          </option>
        ))}
      </Form.Select>
      <div className="pt-2">{renderSelectedCouponCode()}</div>
    </div>
  )
}

export default CouponCodeSelector
