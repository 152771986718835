import { format } from "date-fns"
import Decimal from "decimal.js"

import { dollarsToCents } from "../Billing/Items/helpers"
import {
  DISCOUNT_APPLY_TO_ELECTRIC,
  DISCOUNT_APPLY_TO_STORAGE,
} from "./Steps/shared/Discounts/constants"
import {
  AUTOPAY_ENABLED,
  DEFAULT_RATE_ID,
  NO_COUPON_CODE_CHOSEN_VALUE,
  NO_ELECTRIC_CHOSEN_VALUE,
} from "./constants"

export const friendlyPricingStructureUnitName = (pricingStructure) => {
  switch (pricingStructure) {
    case "per_day":
      return "per night"
    case "per_month":
      return "per month"
    case "per_quantity":
      return "per quantity"
    default:
      return ""
  }
}

export const friendlyRatePricingStructureName = (pricingStructure) => {
  switch (pricingStructure) {
    case "per_day":
      return "Daily flat rate"
    case "by_foot_per_day":
      return "Daily per foot"
    case "sqft_per_day":
      return "Daily per square foot"
    case "per_month":
      return "Monthly flat rate"
    case "by_foot_per_month":
      return "Monthly per foot"
    case "sqft_per_month":
      return "Monthly per square foot"
    case "per_stay":
      return "Full stay flat rate"
    case "by_foot_per_stay":
      return "Full stay per foot"
    case "sqft_per_stay":
      return "Full stay per square foot"
    default:
      return "Invalid pricing structure"
  }
}

export const friendlyInstallmentScheduleName = (schedule) => {
  switch (schedule) {
    case "upfront":
      return "Upfront"
    case "check_in":
      return "Check-in"
    case "check_out":
      return "Check-out"
    case "deposit_one_night":
      return "Deposit (one night)"
    case "deposit_quarter":
      return "Deposit (25%)"
    case "deposit_half":
      return "Deposit (50%)"
    case "monthly":
      return "Monthly"
    default:
      return ""
  }
}

export const formatFullStayDiscounts = (discounts) =>
  discounts.map(({ category, name, applyTo, discountType, amount }) => ({
    category,
    name,
    amount,
    apply_to: applyTo,
    discount_type: discountType,
  }))

const discountsByApplyTo = ({ discounts, applyTo }) =>
  discounts.filter((discount) => discount.applyTo === applyTo)

export const discountsErrorMessage = ({
  discounts,
  couponCodeApplied,
  electricItemSelected,
}) => {
  const numCouponCodeDiscounts = couponCodeApplied ? 1 : 0 // a coupon code counts as 1 storage discount AND 1 electric discount
  const storageDiscounts = discountsByApplyTo({
    discounts,
    applyTo: DISCOUNT_APPLY_TO_STORAGE,
  })
  const numStorageDiscounts = storageDiscounts.length + numCouponCodeDiscounts
  const electricDiscounts = discountsByApplyTo({
    discounts,
    applyTo: DISCOUNT_APPLY_TO_ELECTRIC,
  })
  const numElectricDiscounts = electricDiscounts.length + numCouponCodeDiscounts

  if (electricDiscounts.length > 0 && !electricItemSelected) {
    return "Must select an electric item to apply an electric discount."
  } else if (numStorageDiscounts > 3 || numElectricDiscounts > 3) {
    const discountLimit = numCouponCodeDiscounts > 0 ? "2" : "3"
    return `Limit ${discountLimit} discounts per type (boat storage, electric)${
      numCouponCodeDiscounts > 0 ? " when coupon code is added" : ""
    }.`
  } else {
    return null
  }
}

export const formatRate = ({ id, pricingStructure, amount, taxRate }) => {
  if (id === DEFAULT_RATE_ID) {
    return null
  }

  return {
    pricing_structure: pricingStructure,
    amount: amount ? dollarsToCents(amount) : 0,
    tax_rate: taxRate ? new Decimal(taxRate).dividedBy(100).toNumber() : 0.0,
  }
}

export const formatCreateReservationParams = ({
  data,
  billingSchedules,
  waitlistId = null,
}) => {
  const billingSchedule = billingSchedules.find(
    ({ id }) => id === data.billing_schedule_id
  )
  const electricProductId =
    data.electric_product_id === NO_ELECTRIC_CHOSEN_VALUE
      ? null
      : data.electric_product_id
  const promoCodeId =
    data.coupon_code_id === NO_COUPON_CODE_CHOSEN_VALUE
      ? null
      : data.coupon_code_id
  const params = {
    check_in_date: format(data.arrival, "yyyy-MM-dd"),
    check_out_date: format(data.departure, "yyyy-MM-dd"),
    special_request: data.special_request,
    payment_type: data.payment_type,
    billing_schedule: billingSchedule.schedule,
    stripe_card_id:
      data.payment_type === AUTOPAY_ENABLED ? data.stripe_card_id : "",
    emails_enabled: data.confirmation_email_enabled,
    contact_id: data.contact_id,
    contact_boat_id: data.contact_boat_id,
    waitlist_id: waitlistId || null,
    storage_product_id: data.storage_product_id,
    electric_product_id: electricProductId,
    fees_and_discounts: JSON.stringify(formatFullStayDiscounts(data.discounts)),
    promo_code_id: promoCodeId,
  }

  if (data.rate.id !== DEFAULT_RATE_ID) {
    params.rate = formatRate({
      id: data.rate.id,
      pricingStructure: data.rate.pricing_structure,
      amount: data.rate.amount,
      taxRate: data.rate.tax_rate,
    })
  }

  return params
}
