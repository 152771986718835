import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import NumericInput from "src/main/PointOfSale/CurrentSalePanel/NumericInput"

import Form from "src/components/Form"

import useDebounce from "src/hooks/use_debounce"
import { useTracker } from "src/hooks/use_tracker"

import {
  CART_ITEM_DISCOUNT_EDITED,
  CART_ITEM_NOTE_EDITED,
  CART_ITEM_PRICE_EDITED,
  CART_ITEM_QTY_EDITED,
  CART_ITEM_TAX_EDITED,
} from "../amplitude_events"
import { validatePrecision } from "./validators"

const ServerCartItemForm = ({ item, index, disabled = false }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext()

  const tracker = useTracker()

  const formId = `cart[${index}]`
  const errorsForItem = errors.cart?.[index]

  const [note, setNote] = useState(item.note)

  const [debouncedUpdateNote] = useDebounce((newNote, onChange) => {
    onChange(newNote)
    tracker.trackEvent(CART_ITEM_NOTE_EDITED)
  }, 500)

  const handleNoteChange = useCallback(
    (event, onChange) => {
      const newNote = event.target.value
      setNote(newNote)
      debouncedUpdateNote(newNote, onChange)
    },
    [debouncedUpdateNote]
  )

  const renderQuantityInput = () => {
    const inputName = `${formId}.quantity`
    return (
      <div>
        <Form.Label small htmlFor={inputName}>
          Quantity
        </Form.Label>
        <Controller
          name={inputName}
          control={control}
          defaultValue={item.quantity}
          rules={{
            required: "Quantity is required",
            validate: validatePrecision({ name: "Quantity", precision: 5 }),
            min: { value: 0, message: "Must be positive" },
          }}
          render={({ field: { onChange, value } }) => (
            <NumericInput
              id={inputName}
              value={value}
              onChange={(updatedValue) => {
                onChange(updatedValue)
                tracker.trackEvent(CART_ITEM_QTY_EDITED)
              }}
              precision={5}
              smartFormat={true}
              disabled={disabled}
              hasErrors={Boolean(errorsForItem?.quantity)}
            />
          )}
        />
      </div>
    )
  }

  const renderPriceInput = () => {
    const inputName = `${formId}.price`
    return (
      <div>
        <Form.Label small htmlFor={inputName}>
          Price
        </Form.Label>
        <Controller
          name={inputName}
          control={control}
          defaultValue={item.price}
          rules={{
            required: "Price is required",
            validate: validatePrecision({
              name: "Price",
              precision: item.pricePrecision,
            }),
            min: { value: 0, message: "Price cannot be less than $0.00" },
          }}
          render={({ field: { onChange, value } }) => (
            <NumericInput
              id={inputName}
              value={value}
              onChange={(updatedValue) => {
                onChange(updatedValue)
                tracker.trackEvent(CART_ITEM_PRICE_EDITED)
              }}
              precision={item.pricePrecision === "cents" ? 2 : 4}
              valueMultiplier={item.pricePrecision === "cents" ? 100 : 10000}
              icon="$"
              iconPosition="left"
              disabled={disabled}
              hasErrors={Boolean(errorsForItem?.price)}
            />
          )}
        />
      </div>
    )
  }

  const renderTaxInput = () => {
    const inputName = `${formId}.taxRate`
    return (
      <div>
        <Form.Label small htmlFor={inputName}>
          Tax
        </Form.Label>
        <Controller
          name={inputName}
          control={control}
          defaultValue={item.taxRate}
          rules={{
            required: "Tax is required",
            validate: validatePrecision({ name: "Tax", precision: 6 }),
            min: { value: 0, message: "Tax cannot be less than 0%" },
            max: { value: 100, message: "Tax cannot be more than 100%" },
          }}
          render={({ field: { onChange, value } }) => (
            <NumericInput
              id={inputName}
              value={value * 100} // display as percent
              onChange={(updatedValue) => {
                onChange(updatedValue / 100)
                tracker.trackEvent(CART_ITEM_TAX_EDITED)
              }}
              precision={4}
              icon="%"
              smartFormat={true}
              disabled={disabled}
              hasErrors={Boolean(errorsForItem?.taxRate)}
            />
          )}
        />
      </div>
    )
  }

  const renderDiscountInput = () => {
    const inputName = `${formId}.discount`
    return (
      <div>
        <Form.Label small htmlFor={inputName}>
          Discount
        </Form.Label>
        <Controller
          name={inputName}
          control={control}
          defaultValue={item.discount}
          rules={{
            required: "Discount is required",
            validate: validatePrecision({
              name: "Discount",
              precision: 0,
              message: "Discount must be a whole number",
            }),
            min: { value: 0, message: "Discount must be greater than 0" },
            max: { value: 100, message: "Discount must be less than 100" },
          }}
          render={({ field: { onChange, value } }) => (
            <NumericInput
              id={inputName}
              value={value}
              onChange={(updatedValue) => {
                onChange(updatedValue)
                tracker.trackEvent(CART_ITEM_DISCOUNT_EDITED)
              }}
              precision={0}
              icon="%"
              disabled={disabled}
              hasErrors={Boolean(errorsForItem?.discount)}
            />
          )}
        />
      </div>
    )
  }

  const renderNoteInput = () => {
    const inputName = `${formId}.note`
    return (
      <div className="w-full">
        <Form.Label small htmlFor={inputName}>
          Item Note
        </Form.Label>
        <Controller
          name={inputName}
          control={control}
          defaultValue={note}
          render={({ field: { onChange } }) => (
            <Form.Textarea
              rows={1}
              id={inputName}
              value={note}
              onChange={(e) => {
                handleNoteChange(e, onChange)
              }}
              disabled={disabled}
              hasErrors={Boolean(errorsForItem?.note)}
            />
          )}
        />
        <div className="text-muted italic">
          Visible on customer invoice/receipt
        </div>
      </div>
    )
  }

  return (
    <div className="flex w-full flex-col pb-1">
      <div className="grid h-full grid-cols-2 gap-x-3 gap-y-2 pt-2">
        {renderQuantityInput()}
        {renderPriceInput()}
        {renderTaxInput()}
        {renderDiscountInput()}
        <div className="col-span-2">{renderNoteInput()}</div>
      </div>
      {errorsForItem && (
        <div className="flex w-full flex-col pt-2 text-xs">
          <Form.Error>{errorsForItem.quantity?.message}</Form.Error>
          <Form.Error>{errorsForItem.price?.message}</Form.Error>
          <Form.Error>{errorsForItem.taxRate?.message}</Form.Error>
          <Form.Error>{errorsForItem.discount?.message}</Form.Error>
          <Form.Error>{errorsForItem.note?.message}</Form.Error>
        </div>
      )}
    </div>
  )
}

ServerCartItemForm.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    pricePrecision: PropTypes.oneOf(["cents", "hundredths_of_cents"])
      .isRequired,
    taxRate: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    note: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

export default ServerCartItemForm
