import PropTypes from "prop-types"
import React, { createContext, useContext, useState } from "react"
import { POSContext } from "src/main/PointOfSale/Root"

import { useModalToggle } from "src/hooks/use_modal_toggle"
import useWindowSize from "src/hooks/use_window_size"

import CurrentSalePanel from "../CurrentSalePanel"
import SmallScreenCheckout from "../SmallScreen/SmallScreenCheckout"
import CompletedSalePanel from "./CompletedSalePanel"
import PaymentMethodPanel from "./PaymentMethodPanel"
import PaymentModal from "./PaymentModal"

export const CHECKOUT_PHASES = {
  unpaid: "unpaid",
  completed: "completed",
  canceled: "canceled",
}

export const CheckoutContext = createContext()

const Checkout = ({
  noCardReadersConfigured,
  enableBluetoothCardReader,
  enableTips,
}) => {
  const { isLargeScreen } = useWindowSize()

  const {
    cart: { updateCart },
    serverCartFeatureEnabled,
  } = useContext(POSContext)

  const [isPaymentModalOpen, showPaymentModal, hidePaymentModal] =
    useModalToggle()

  const [phase, setPhase] = useState(CHECKOUT_PHASES.unpaid)
  const [sale, setSale] = useState()
  const [checkout, setCheckout] = useState()
  const [activePaymentFlow, setActivePaymentFlow] = useState(null)

  const launchPaymentFlow = ({ paymentFlow }) => {
    setActivePaymentFlow(paymentFlow)
    showPaymentModal()
  }

  const exitPaymentFlow = () => {
    hidePaymentModal()
    setActivePaymentFlow(null)
  }

  const onSaleCompleted = (sale, checkout) => {
    setSale(sale)
    // this may be called when retrying a payment, and we don't get the checkout back from the server.
    // it will already be set in state though from when we first created the sale
    if (checkout) {
      setCheckout(checkout)
    }
    if (serverCartFeatureEnabled) {
      updateCart({ status: "completed" })
    }
    setPhase(CHECKOUT_PHASES.completed)
  }

  const onPaymentCanceled = (sale, checkout) => {
    setSale(sale)
    // we don't get the checkout back from the server when canceling the payment.
    // it will already be set in state though from when we first created the sale
    if (checkout) {
      setCheckout(checkout)
    }
    setPhase(CHECKOUT_PHASES.canceled)
  }

  const retryPayment = () => {
    setPhase(CHECKOUT_PHASES.unpaid)
  }

  const renderBody = () => {
    switch (phase) {
      case CHECKOUT_PHASES.unpaid:
        return <PaymentMethodPanel launchPaymentFlow={launchPaymentFlow} />
      case CHECKOUT_PHASES.completed:
      case CHECKOUT_PHASES.canceled:
        return <CompletedSalePanel />
    }
  }

  return (
    <CheckoutContext.Provider
      value={{
        noCardReadersConfigured,
        enableBluetoothCardReader,
        enableTips,
        phase,
        setPhase,
        sale,
        setSale,
        checkout,
        setCheckout,
        onSaleCompleted,
        onPaymentCanceled,
        retryPayment,
        activePaymentFlow,
        launchPaymentFlow,
        exitPaymentFlow,
      }}
    >
      {/* Adaptive Designs - Small Screens */}
      {!isLargeScreen ? (
        <div data-testid="adaptive-checkout" className="flex h-full flex-col">
          <SmallScreenCheckout />
        </div>
      ) : null}

      {/* Responsive Designs - All other Screens */}
      {isLargeScreen ? (
        <>
          <div
            data-testid="responsive-checkout"
            className="flex h-full w-full flex-col-reverse divide-y divide-y-reverse overflow-y-auto md:flex-row md:divide-x md:divide-y-0"
          >
            <div className="flex-1 py-2 md:flex-2 md:py-0">
              <CurrentSalePanel
                disableFields
                navigationButton={{ name: "Back", path: "/" }}
                hideNavigationButton={[
                  CHECKOUT_PHASES.completed,
                  CHECKOUT_PHASES.canceled,
                ].includes(phase)}
                sale={sale}
              />
            </div>
            <div className="flex-1 py-2 md:flex-3 md:py-0">{renderBody()}</div>
          </div>
          {isPaymentModalOpen ? (
            <PaymentModal
              onClose={exitPaymentFlow}
              paymentFlow={activePaymentFlow}
            />
          ) : null}
        </>
      ) : null}
    </CheckoutContext.Provider>
  )
}

Checkout.propTypes = {
  noCardReadersConfigured: PropTypes.bool.isRequired,
  enableBluetoothCardReader: PropTypes.bool.isRequired,
  enableTips: PropTypes.bool.isRequired,
}

export default Checkout
