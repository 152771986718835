import classNames from "classnames"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"

import { WizardContext } from "../../../WizardContextProvider"

const ContactSection = () => {
  const { manageContactsPath, waitlistId } = useContext(WizardContext)
  const { watch } = useFormContext()

  const [contact, contactBoat] = watch(["contact", "contact_boat"])

  const boatTypeIcon = () => {
    const sailboat = contactBoat.boatType === "sail"
    return (
      <i
        role="icon"
        data-testid="trw-contact-boat-icon"
        className={classNames("icon text-muted h4 mr-3", {
          "icon-sailboat-thin": sailboat,
          "icon-md-directions-boat": !sailboat,
        })}
      />
    )
  }

  if (contact?.encodedId) {
    return (
      <div className="flex flex-col pt-2" data-testid="trw-contact-display">
        <a
          className="h4 text-link m-0 mb-2 font-semibold"
          href={`${manageContactsPath}/${contact.encodedId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {contact.name}
        </a>
        {contactBoat?.encodedId ? (
          <div>
            {boatTypeIcon()}
            {contactBoat.displayName}
          </div>
        ) : null}
        <div>
          <i className="icon icon-md-mail text-muted h4 mr-3" />
          {contact.email}
        </div>
        <div>
          <i className="icon icon-phone text-muted h4 mr-3" />
          {contact.displayPhone}
        </div>
        {waitlistId && (
          <div className="text-teal-800">
            <i className="icon icon-list h4 mr-3" />
            This boater is getting off the waitlist!
          </div>
        )}
      </div>
    )
  }
  return null
}

export default ContactSection
