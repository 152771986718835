import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"
import {
  centsToDollars,
  hundredthsOfCentsToDollars,
} from "src/main/Billing/Items/helpers"

import { itemSubtotalDollars } from "../../CurrentSalePanel/helpers"
import { POSContext } from "../../Root"
import ConfirmationTotals from "./ConfirmationTotals"

const ItemRow = ({ item, index }) => {
  const { serverCartFeatureEnabled } = useContext(POSContext)
  const { watch } = useFormContext()

  // when we remove the serverCartFeatureEnabled feature flag we can pull quantity and price from the item
  const { quantity, price } = watch(`cart[${index}]`)
  const conversionFunction =
    item.pricePrecision === "cents"
      ? centsToDollars
      : hundredthsOfCentsToDollars

  return (
    <div className="flex justify-between space-y-2">
      <div className="font-semibold">
        <span className="text-gray-800">{item.name}</span>{" "}
        <span className="text-gray-600">({quantity})</span>
      </div>
      <span>
        $
        {itemSubtotalDollars({
          quantity,
          price: serverCartFeatureEnabled ? conversionFunction(price) : price,
        })}
      </span>
    </div>
  )
}

ItemRow.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    pricePrecision: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
}

const ConfirmationScreen = ({ details }) => {
  const { cart } = useContext(POSContext)
  return (
    <div>
      <div>
        {cart.fields.map((item, index) => (
          <ItemRow item={item} index={index} key={item.id} />
        ))}
      </div>
      <hr />
      <ConfirmationTotals />
      <div className="mb-4">{details}</div>
    </div>
  )
}

ConfirmationScreen.propTypes = {
  details: PropTypes.node,
}

export default ConfirmationScreen
