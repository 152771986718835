import React, { useContext, useEffect, useMemo, useState } from "react"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"

import Button from "src/components/Button"

import { printReceipt } from "src/api/PointOfSale/sales"

import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import PanelWrapper from "../../PanelWrapper"
import { POSContext } from "../../Root"
import {
  NEW_SALE_PRESSED,
  TXN_COMPLETE_PAGE_VIEWED,
} from "../../amplitude_events"
import { useSelectedDefaultPrinterId } from "../../hooks/useSelectedDefaultPrinterId"
import ChangePrinterModal from "../ChangePrinterModal"
import { SALE_STATUS } from "../constants"
import { nameToInitials } from "../helpers"
import { CheckoutContext } from "../index"
import PrintButton from "./PrintButton"
import RetryPaymentButton from "./RetryPaymentButton"
import SaleDetailsLink from "./SaleDetailsLink"

const STATUS_COPY = {
  [SALE_STATUS.settled]: {
    iconColor: "text-teal-400",
    icon: "icon-contract-circle-check",
    text: "Success! Transaction complete.",
  },
  [SALE_STATUS.scheduled]: {
    iconColor: "text-teal-400",
    icon: "icon-contract-circle-check",
    text: "Success! Sale saved.",
  },
  [SALE_STATUS.processing]: {
    iconColor: "text-orange-400",
    icon: "icon-circle-cycle",
    text: "Payment processing.",
  },
  [SALE_STATUS.failed]: {
    iconColor: "text-red-400",
    icon: "icon-exclamation-circle",
    text: "Payment failed",
  },
  [SALE_STATUS.canceled]: {
    iconColor: "text-yellow-400",
    icon: "icon-cancel-circle",
    text: "Unpaid sale",
  },
  past_due: {
    iconColor: "text-teal-400",
    icon: "icon-contract-circle-check",
    text: "Success! Sale saved.",
  },
}

const CompletedSalePanel = () => {
  const {
    cart: { clearCart },
    receiptPrinters,
    marinaAccess,
  } = useContext(POSContext)
  const { sale, checkout, retryPayment } = useContext(CheckoutContext)

  const tracker = useTracker()
  const showToast = useToast()

  useEffect(() => {
    switch (sale.status) {
      case SALE_STATUS.settled:
      case SALE_STATUS.scheduled:
        return tracker.trackEvent(TXN_COMPLETE_PAGE_VIEWED, {
          page: "payment_success",
        })
      case SALE_STATUS.failed:
        return tracker.trackEvent(TXN_COMPLETE_PAGE_VIEWED, {
          page: "payment_failed",
        })
      case SALE_STATUS.canceled:
        return tracker.trackEvent(TXN_COMPLETE_PAGE_VIEWED, {
          page: "payment_canceled",
        })
    }
  }, [sale, tracker])

  const [showChangePrinterModal, setShowChangePrinterModal] = useState(false)
  const [defaultPrinterId] = useSelectedDefaultPrinterId()

  const marinaSlug = getCurrentMarinaSlug()
  const statusContent = STATUS_COPY[sale.status]

  const showContact =
    sale?.contact?.id && sale?.contact?.name !== "Point of Sale Guest"

  const selectedPrinter = useMemo(() => {
    return receiptPrinters.find((printer) => printer.id === defaultPrinterId)
  }, [defaultPrinterId, receiptPrinters])

  const { mutate: print, isLoading } = useMutation({
    mutationFn: (body) => {
      if (sale.encodedId) {
        return printReceipt(sale.encodedId, body)
      }
    },
    onSuccess: () => {
      showToast("Printing receipt", { type: "success" })
    },
    onError: () => {
      showToast("Failed to print receipt", { type: "error" })
    },
  })

  const printButtonDisabled = isLoading || !sale.encodedId
  const saleDetailsButtonDisabled = !sale.encodedId

  const handleNewSaleClick = async () => {
    tracker.trackEvent(NEW_SALE_PRESSED)
    await clearCart()
  }

  return (
    <>
      <PanelWrapper justifyBetween>
        <div className="flex items-center justify-end space-x-2">
          {showContact ? (
            <>
              <div className="w-fit rounded-full bg-orange-400 p-2 text-center font-bold leading-none text-white">
                {nameToInitials(sale.contact.name ?? "")}
              </div>
              <span className="font-semibold">{sale.contact.name}</span>
            </>
          ) : null}
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-center">
            <i
              className={`icon ${statusContent.icon} ${statusContent.iconColor} text-7xl`}
            />
          </div>
          <div className="my-4 text-center text-2xl font-bold">
            {statusContent.text}
          </div>

          {[SALE_STATUS.canceled, SALE_STATUS.failed].includes(sale.status) ? (
            <RetryPaymentButton retryPayment={retryPayment} />
          ) : null}

          <div className="flex flex-col-reverse justify-around space-y-4 space-y-reverse md:flex-row md:space-x-4 md:space-y-0">
            <PrintButton
              disabled={printButtonDisabled}
              isLoading={isLoading}
              selectedPrinterName={selectedPrinter?.name}
              onClick={() => {
                if (!selectedPrinter) {
                  setShowChangePrinterModal(true)
                } else {
                  print({
                    manage_id: marinaSlug,
                    printer_id: selectedPrinter.id,
                    checkout_id: checkout.id,
                  })
                }
              }}
            />

            <SaleDetailsLink
              linkPath={`/manage/${marinaSlug}/point_of_sale/sales/${sale.encodedId}`}
              disabled={saleDetailsButtonDisabled}
            />
          </div>
          <div>
            {marinaAccess.manageHardware && (
              <Button
                variant="ghost"
                onClick={() => setShowChangePrinterModal(true)}
              >
                Change Printer
              </Button>
            )}
          </div>
        </div>
        <div className="w-full">
          <Link to="/" onClick={handleNewSaleClick}>
            <Button variant="primary" fullWidth>
              New Sale
            </Button>
          </Link>
        </div>
      </PanelWrapper>

      {showChangePrinterModal && (
        <ChangePrinterModal onClose={() => setShowChangePrinterModal(false)} />
      )}
    </>
  )
}

export default CompletedSalePanel
