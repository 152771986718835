import { differenceInDays, format } from "date-fns"
import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React, { createContext, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { useMutation, useQuery } from "react-query"

import { getContactPaymentMethods } from "src/api/Contacts"
import {
  calculatePrice,
  getAvailability,
  getCouponCodes,
} from "src/api/TransientReservationWizard"

import useDebounce from "src/hooks/use_debounce"

import { centsToDollars } from "src/utils/UnitConversion"

import {
  ADD_NEW_CONTACT,
  ADD_NEW_CONTACT_BOAT,
  CUSTOM_RATE_ID,
  DEFAULT_RATE_ID,
  ELECTRIC_MONTHLY_BILLING_STRUCTURE,
  MONTHLY_BILLING_SCHEDULE,
  NO_COUPON_CODE_CHOSEN_VALUE,
  NO_ELECTRIC_CHOSEN_VALUE,
  NO_PRICING_STRUCTURE_CHOSEN_VALUE,
  STEPS,
} from "./constants"
import {
  discountsErrorMessage,
  formatFullStayDiscounts,
  formatRate,
} from "./helpers"
import {
  billingSchedulePropTypes,
  couponCodePropTypes,
  electricProductPropTypes,
  ratePropTypes,
  storageProductPropTypes,
} from "./index"

export const WizardContext = createContext({
  currentStep: STEPS.availability,
  stepEnabled: () => {},
  goToStep: () => {},
  handleArrivalChange: () => {},
  handleDepartureChange: () => {},
  storageProducts: [],
  waitlistPath: "",
})

const WizardContextProvider = ({
  children,
  initialStep = STEPS.availability,
  billingSchedules,
  storageProducts,
  electricProducts,
  rateOptions,
  pricingStructures,
  couponCodes,
  waitlistPath,
  promoCodesPath,
  marinaSlug,
  handleOpenDiscountsModal,
  handleRemoveDiscount,
  telescopeCtaPath,
  telescopeEnabled,
  manageContactsPath,
  waitlistId,
  loadingCreateReservation,
  errorCreatingReservation,
  reservationSettingsPath,
  manageElectricItemsPath,
  shortTermDefaultBillingSchedule,
  longTermDefaultBillingSchedule,
}) => {
  const {
    setValue,
    watch,
    trigger,
    formState: { dirtyFields },
    clearErrors,
  } = useFormContext()
  const [
    arrival,
    departure,
    electricProductId,
    billingScheduleId,
    storageProductId,
    contactId,
    contact,
    contactBoatId,
    contactBoat,
    newBoatLOA,
    newBoatBeam,
    stripeCardId,
    discounts,
    couponCodeId,
    rateId,
    ratePricingStructure,
    rateAmount,
    rateTax,
  ] = watch([
    "arrival",
    "departure",
    "electric_product_id",
    "billing_schedule_id",
    "storage_product_id",
    "contact_id",
    "contact",
    "contact_boat_id",
    "contact_boat",
    "newBoat.lengthOverallFeet",
    "newBoat.beamFeet",
    "stripe_card_id",
    "discounts",
    "coupon_code_id",
    "rate.id",
    "rate.pricing_structure",
    "rate.amount",
    "rate.tax_rate",
  ])

  const setInitialStep = () => {
    if (initialStep === STEPS.summary && (!contactId || !contactBoatId)) {
      // Edge case handling for potential bad props being passed in from the backend.
      // Initial step cannot be set to "summary" if the contact & boat are not present.
      return STEPS.contact
    }
    return initialStep
  }
  const [currentStep, setCurrentStep] = useState(setInitialStep())

  const handleArrivalChange = (dateSelection) => {
    setValue("arrival", dateSelection)
    trigger("arrival")
  }

  const handleDepartureChange = (dateSelection) => {
    setValue("departure", dateSelection)
    trigger("departure")
  }

  const stepEnabled = (step) => {
    if (loadingCreateReservation) {
      return false
    }

    if ([STEPS.availability, STEPS.contact].includes(step)) {
      return true
    } else if (step === STEPS.summary && contactId && contactBoatId) {
      return true
    } else {
      return false
    }
  }

  const goToStep = (step) => {
    if (stepEnabled(step)) {
      setCurrentStep(step)
    }
  }

  const {
    mutate: getAvailabilityData,
    isLoading: isLoadingAvailabilityData,
    isError: getAvailabilityDataError,
    data: availabilityData,
  } = useMutation(() => {
    const params = {
      check_in_date: arrival,
      check_out_date: departure,
      storage_product_id: storageProductId,
    }
    return getAvailability({ marinaSlug, params })
  })

  useEffect(() => {
    if (arrival && departure) {
      getAvailabilityData()
    }
  }, [arrival, departure, getAvailabilityData, storageProductId])

  const boatDimensionsForCalculatePrice = () => {
    if (!contactBoatId) {
      return { beam: newBoatBeam * 12, length_overall: newBoatLOA * 12 }
    } else if (contactBoatId === ADD_NEW_CONTACT_BOAT) {
      return {
        beam: (contactBoat.beam || 0) * 12,
        length_overall: (contactBoat.length_overall || 0) * 12,
      }
    } else {
      return {
        // If we have an existing contact boat selected, we do not want to send the beam and length_overall.
        beam: null,
        length_overall: null,
      }
    }
  }

  const {
    mutate: getPriceEstimate,
    isLoading: isLoadingPriceEstimate,
    isError: getPriceEstimateError,
    data: priceEstimateData,
    error: priceEstimateError,
  } = useMutation(() => {
    const missingDates = !arrival || !departure
    const missingPricingStructure =
      rateId === CUSTOM_RATE_ID &&
      ratePricingStructure === NO_PRICING_STRUCTURE_CHOSEN_VALUE
    if (missingDates || missingPricingStructure) {
      return Promise.resolve(null)
    }

    // eslint-disable-next-line camelcase
    const { beam, length_overall } = boatDimensionsForCalculatePrice()
    const billingSchedule = billingSchedules.find(
      ({ id }) => id === billingScheduleId
    )
    const params = {
      check_in_date: format(arrival, "yyyy-MM-dd"),
      check_out_date: format(departure, "yyyy-MM-dd"),
      storage_product_id: storageProductId,
      length_overall,
      beam,
      contact_id: contactId !== ADD_NEW_CONTACT ? contactId : null,
      contact_boat_id:
        contactBoatId !== ADD_NEW_CONTACT_BOAT ? contactBoatId : null,
      electric_product_id:
        electricProductId === NO_ELECTRIC_CHOSEN_VALUE
          ? null
          : electricProductId,
      billing_schedule: billingSchedule.schedule,
      fees_and_discounts: JSON.stringify(formatFullStayDiscounts(discounts)),
      promo_code_id:
        couponCodeId === NO_COUPON_CODE_CHOSEN_VALUE ? null : couponCodeId,
    }

    if (rateId !== DEFAULT_RATE_ID) {
      params.rate = formatRate({
        id: rateId,
        pricingStructure: ratePricingStructure,
        amount: rateAmount,
        taxRate: rateTax,
      })
    }
    return calculatePrice({ marinaSlug, params })
  })

  const [debouncedGetPriceEstimate] = useDebounce(getPriceEstimate)

  useEffect(() => {
    debouncedGetPriceEstimate()
  }, [
    arrival,
    debouncedGetPriceEstimate,
    departure,
    storageProductId,
    newBoatLOA,
    newBoatBeam,
    contactBoat,
    contactBoatId,
    contactId,
    electricProductId,
    billingScheduleId,
    discounts,
    couponCodeId,
    rateId,
    ratePricingStructure,
    rateAmount,
    rateTax,
  ])

  const [isMonthlyBilling, setIsMonthlyBilling] = useState(false)

  useEffect(() => {
    const selectedBillingSchedule = billingSchedules.find(
      (billingSchedule) => billingSchedule.id === billingScheduleId
    )
    setIsMonthlyBilling(
      selectedBillingSchedule.schedule === MONTHLY_BILLING_SCHEDULE
    )
  }, [billingScheduleId, billingSchedules])

  useEffect(() => {
    // reset the electric product to "none" IF the marina chooses monthly billing AFTER selecting a non-monthly electric product
    if (isMonthlyBilling && electricProductId !== NO_ELECTRIC_CHOSEN_VALUE) {
      const electricProduct = electricProducts.find(
        (product) => product.id === electricProductId
      )
      const isMonthlyElectric =
        electricProduct.defaultPricingStructure ===
        ELECTRIC_MONTHLY_BILLING_STRUCTURE
      if (!isMonthlyElectric) {
        setValue("electric_product_id", NO_ELECTRIC_CHOSEN_VALUE)
      }
    }
  }, [isMonthlyBilling, electricProductId, electricProducts])

  useEffect(() => {
    // reset the rate IF the marina chooses monthly billing AFTER selecting a non-monthly or default rate
    if (!isMonthlyBilling) return

    const isMonthlyRate = ratePricingStructure.includes("month")
    if (!isMonthlyRate || rateId === DEFAULT_RATE_ID) {
      const firstMonthlyPricingStructure =
        pricingStructures.find((pricingStructure) =>
          pricingStructure.includes("month")
        ) || NO_PRICING_STRUCTURE_CHOSEN_VALUE
      if (rateId === CUSTOM_RATE_ID) {
        // reset ONLY the rate pricing structure IF using a custom rate with a non-monthly pricing structure
        setValue("rate.pricing_structure", firstMonthlyPricingStructure)
      } else {
        // reset the entire rate IF using a non-monthly named rate
        setValue("rate", {
          id: CUSTOM_RATE_ID,
          pricing_structure: firstMonthlyPricingStructure,
          amount: null,
          tax_rate: null,
        })
      }
    }
  }, [isMonthlyBilling])

  useEffect(() => {
    // When selecting a rate, set all the rate-related form values
    const rate = rateOptions.find((rate) => rate.id.toString() === rateId)
    clearErrors(["rate.amount", "rate.tax_rate"])
    if (rateId === DEFAULT_RATE_ID) {
      setValue("rate", {
        id: rateId,
        pricing_structure: NO_PRICING_STRUCTURE_CHOSEN_VALUE,
        amount: null,
        tax_rate: null,
      })
    } else if (rateId === CUSTOM_RATE_ID) {
      const firstPricingStructureOption = isMonthlyBilling
        ? pricingStructures.find((pricingStructure) =>
            pricingStructure.includes("month")
          )
        : pricingStructures[0]
      setValue("rate", {
        id: rateId,
        pricing_structure: firstPricingStructureOption,
        amount: null,
        tax_rate: null,
      })
    } else if (rate) {
      setValue("rate", {
        id: rate.id,
        pricing_structure: rate.mbmTranslatedPricingStructure,
        amount: centsToDollars(rate.amount),
        tax_rate: new Decimal(rate.taxRate || 0).mul(100).toNumber(),
      })
    }
  }, [rateId, rateOptions])

  useEffect(() => {
    /*
      This useEffect handles setting the default billing schedule based on the length of the reservation,
      derived from the arrival/departure input.
    */

    if (dirtyFields.billing_schedule_id) {
      // If the user already changed the billing schedule to a non-default value, do *not* change it on their behalf.
      return
    }

    if (differenceInDays(departure, arrival) > 30) {
      setValue("billing_schedule_id", longTermDefaultBillingSchedule.id)
    } else {
      setValue("billing_schedule_id", shortTermDefaultBillingSchedule.id)
    }
  }, [
    arrival,
    departure,
    longTermDefaultBillingSchedule.id,
    shortTermDefaultBillingSchedule.id,
  ])

  const {
    data: contactCards,
    isLoading: contactCardsLoading,
    isError: contactCardsError,
  } = useQuery(
    ["trw-contact-cards", marinaSlug, contact?.encodedId],
    () =>
      getContactPaymentMethods({ marinaSlug, contactId: contact?.encodedId }),
    {
      initialData: [],
      enabled: Boolean(contact?.encodedId),
    }
  )

  useEffect(() => {
    setValue("stripe_card_id", "")
  }, [contact])

  useEffect(() => {
    if (contactCards.length > 0 && stripeCardId === "") {
      setValue("stripe_card_id", contactCards[0].stripePaymentMethodId)
    }
  }, [contactCards, stripeCardId])

  useEffect(() => {
    clearErrors(["stripe_card_id"])
  }, [stripeCardId])

  const [discountsError, setDiscountsError] = useState(null)

  useEffect(() => {
    // If we switch between monthly/non-monthly billing, fully reset the discounts array & coupon code.
    setValue("discounts", [])
    setValue("coupon_code_id", NO_COUPON_CODE_CHOSEN_VALUE)
    setDiscountsError(null)
  }, [isMonthlyBilling])

  useEffect(() => {
    if (!isMonthlyBilling) {
      const errorMessage = discountsErrorMessage({
        discounts,
        couponCodeApplied: couponCodeId !== NO_COUPON_CODE_CHOSEN_VALUE,
        electricItemSelected: electricProductId !== NO_ELECTRIC_CHOSEN_VALUE,
      })

      setDiscountsError(errorMessage)
    }
  }, [
    couponCodeId,
    discounts,
    discountsError,
    isMonthlyBilling,
    electricProductId,
  ])

  const {
    data: couponCodesData,
    isLoading: couponCodesLoading,
    isError: couponCodesError,
  } = useQuery(
    ["trw-coupon-codes", marinaSlug, contact?.encodedId, arrival, departure],
    () => {
      const formattedArrival = arrival ? format(arrival, "yyyy-MM-dd") : null
      const formattedDeparture = departure
        ? format(departure, "yyyy-MM-dd")
        : null
      return getCouponCodes({
        marinaSlug,
        arrival: formattedArrival,
        departure: formattedDeparture,
        contactId: contact?.encodedId,
      })
    },
    {
      initialData: {
        discounts: [], // we aren't doing anything with this; this just mirrors what's returned by the endpoint
        promoCodes: couponCodes,
      },
      enabled: Boolean(contact?.encodedId),
      refetchOnWindowFocus: true, // the user might navigate to the settings and add a coupon code mid-form. So, we want to re-fetch when we return to this window to get the updated list.
    }
  )

  return (
    <WizardContext.Provider
      value={{
        marinaSlug,
        currentStep,
        stepEnabled,
        goToStep,
        handleArrivalChange,
        handleDepartureChange,
        storageProducts,
        electricProducts,
        rateOptions,
        pricingStructures,
        billingSchedules,
        waitlistPath,
        promoCodesPath,
        availabilityData,
        isLoadingAvailabilityData,
        getAvailabilityDataError,
        isLoadingPriceEstimate,
        getPriceEstimateError,
        priceEstimateData,
        priceEstimateError,
        isMonthlyBilling,
        contactCards,
        contactCardsLoading,
        contactCardsError,
        openDiscountsModal: handleOpenDiscountsModal,
        discountsModalDisabled: isMonthlyBilling,
        handleRemoveDiscount,
        discountsError,
        couponCodes: couponCodesData?.promoCodes,
        couponCodesLoading,
        couponCodesError,
        telescopeCtaPath,
        telescopeEnabled,
        manageContactsPath,
        waitlistId,
        loadingCreateReservation,
        errorCreatingReservation,
        reservationSettingsPath,
        manageElectricItemsPath,
      }}
    >
      {children}
    </WizardContext.Provider>
  )
}

WizardContextProvider.defaultProps = {
  initialStep: STEPS.availability,
}

WizardContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  marinaSlug: PropTypes.string.isRequired,
  initialStep: PropTypes.oneOf(Object.values(STEPS)),
  billingSchedules: PropTypes.arrayOf(PropTypes.shape(billingSchedulePropTypes))
    .isRequired,
  storageProducts: PropTypes.arrayOf(PropTypes.shape(storageProductPropTypes))
    .isRequired,
  electricProducts: PropTypes.arrayOf(PropTypes.shape(electricProductPropTypes))
    .isRequired,
  rateOptions: PropTypes.arrayOf(PropTypes.shape(ratePropTypes)).isRequired,
  couponCodes: PropTypes.arrayOf(PropTypes.shape(couponCodePropTypes))
    .isRequired,
  waitlistPath: PropTypes.string.isRequired,
  promoCodesPath: PropTypes.string.isRequired,
  handleOpenDiscountsModal: PropTypes.func.isRequired,
  handleRemoveDiscount: PropTypes.func.isRequired,
  pricingStructures: PropTypes.arrayOf(PropTypes.string).isRequired,
  telescopeCtaPath: PropTypes.string.isRequired,
  telescopeEnabled: PropTypes.bool.isRequired,
  manageContactsPath: PropTypes.string.isRequired,
  waitlistId: PropTypes.string,
  loadingCreateReservation: PropTypes.bool.isRequired,
  errorCreatingReservation: PropTypes.bool.isRequired,
  reservationSettingsPath: PropTypes.string.isRequired,
  manageElectricItemsPath: PropTypes.string.isRequired,
  shortTermDefaultBillingSchedule: PropTypes.shape(billingSchedulePropTypes)
    .isRequired,
  longTermDefaultBillingSchedule: PropTypes.shape(billingSchedulePropTypes)
    .isRequired,
}

export default WizardContextProvider
