import { useEffect, useMemo, useRef, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useSelectedSoldByUser } from "src/main/PointOfSale/hooks/useSelectedSoldByUser"
import { v4 as uuidv4 } from "uuid"

import {
  addCartItem,
  createCart,
  getCart,
  removeCartItem,
  updateCart as updateCartApi,
  updateCartItem,
} from "src/api/PointOfSale/cart"

import useDebounce from "src/hooks/use_debounce"
import { useToast } from "src/hooks/use_toast"
import useWindowSize from "src/hooks/use_window_size"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const PanelState = Object.freeze({
  CLOSED: "closed",
  OPEN: "open",
})

const useServerCart = (serverCartId) => {
  const marinaSlug = getCurrentMarinaSlug()
  const queryClient = useQueryClient()
  const showToast = useToast()
  const { isLargeScreen } = useWindowSize()
  const [panelState, setPanelState] = useState({})
  const [cartId, setCartId] = useState(serverCartId)
  const addItemMutex = useRef(Promise.resolve())

  const { data: cartData, isLoading: cartDataLoading } = useQuery({
    queryKey: ["cart", marinaSlug, cartId],
    queryFn: async () => {
      const { data } = await getCart({ marinaSlug, cartId })
      return data
    },
    initialData: {
      id: cartId,
      note: "",
      contactId: null,
      soldById: null,
      cartItems: [],
      lineItems: [],
      totals: {
        subtotal: null,
        discount: null,
        tax: null,
        total: null,
      },
    },
  })

  const methods = useForm({
    defaultValues: { cart: [], note: "" },
    mode: "onBlur",
    values: { cart: cartData.cartItems, note: cartData.note },
  })

  const { fields } = useFieldArray({
    name: "cart",
    control: methods.control,
    keyName: "formId",
  })

  /* Update Sale Note */
  const updateCartNoteMutation = useMutation({
    mutationFn: async (updates) => {
      const { data: updatedCart } = await updateCartApi({
        marinaSlug,
        cart: updates,
        cartId: cartData.id,
      })
      return updatedCart
    },
    onSuccess: (cart) => {
      queryClient.setQueryData(["cart", marinaSlug, cartId], cart)
    },
  })

  const [debouncedUpdateNote] = useDebounce(updateCartNoteMutation.mutate, 1000)

  useEffect(() => {
    const subscription = methods.watch((formData, { name }) => {
      if (name === "note") {
        debouncedUpdateNote({ note: formData.note })
      }
    })

    return () => subscription.unsubscribe()
  }, [debouncedUpdateNote, methods])

  /* Update Cart */
  const updateCartMutation = useMutation({
    mutationFn: async (updates) => {
      const { data: updatedCart } = await updateCartApi({
        marinaSlug,
        cart: updates,
        cartId: cartData.id,
      })
      return updatedCart
    },
    onSuccess: (cart) => {
      queryClient.setQueryData(["cart", marinaSlug, cartId], cart)
    },
  })

  const updateCart = (updates) => {
    updateCartMutation.mutate(updates)
  }

  /* Sync Sold By User */
  const [selectedSoldByUser] = useSelectedSoldByUser()

  useEffect(() => {
    if (selectedSoldByUser) {
      updateCartMutation.mutate({ soldById: selectedSoldByUser.id })
    }
  }, [selectedSoldByUser])

  /* Update Item */
  const updateItemMutation = useMutation({
    mutationFn: async ({ item }) => {
      const { data } = await updateCartItem({
        marinaSlug,
        cartId: cartData.id,
        itemId: item.id,
        item,
      })
      return data
    },
    onSuccess: (cart) => {
      queryClient.setQueryData(["cart", marinaSlug, cartId], cart)
    },
    onError: (error) => {
      showToast(`There was an error updating the item: ${error.message}`, {
        type: "error",
      })
    },
  })

  useEffect(() => {
    const subscription = methods.watch((formData, { name }) => {
      if (name?.startsWith("cart")) {
        const match = name.match(/^cart\[(\d+)\]/)
        if (match) {
          const index = parseInt(match[1])
          const item = formData.cart?.[index]
          if (item) {
            updateItemMutation.mutate({ item })
          }
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [methods, updateItemMutation])

  /* Add Item */
  const addItemMutation = useMutation({
    mutationFn: async ({ item }) => {
      const { data: cart } = await addCartItem({
        marinaSlug,
        cartId,
        item,
      })
      return { cart, item }
    },
    onSuccess: ({ cart, item }) => {
      queryClient.setQueryData(["cart", marinaSlug, cartId], cart)
      if (!isLargeScreen)
        showToast(`${item.name} Added`, { type: "success", duration: 2 })
    },
    onError: (error) => {
      showToast(`There was an error adding the item: ${error.message}`, {
        type: "error",
      })
    },
  })

  const addItemToCart = ({ item: product }) => {
    const item = {
      id: uuidv4(),
      name: product.name,
      productId: product.id,
      price: product.price_per_unit || 0,
      taxRate: product.tax_rate || 0,
      quantity: product.quantity || 1,
      discount: 0,
      discountAmount: 0,
      pricePrecision: product.price_precision,
    }
    closeAllPanels()
    togglePanelOpen({ id: item.id })
    addItemMutex.current = addItemMutex.current.then(async () => {
      return addItemMutation.mutateAsync({ item })
    })

    return addItemMutex.current
  }

  /* Remove Item */
  const removeItemMutation = useMutation({
    mutationFn: async ({ itemId }) => {
      const { data: cart } = await removeCartItem({
        marinaSlug,
        cartId,
        itemId,
      })
      return { cart, itemId }
    },
    onMutate: async ({ itemId }) => {
      await queryClient.cancelQueries(["cart", marinaSlug, cartId])

      const previousCart = queryClient.getQueryData([
        "cart",
        marinaSlug,
        cartId,
      ])

      queryClient.setQueryData(["cart", marinaSlug, cartId], (old) => ({
        ...old,
        cartItems: old.cartItems.filter((item) => item.id !== itemId),
      }))

      return { previousCart }
    },
    onSuccess: async ({ cart }) => {
      queryClient.setQueryData(["cart", marinaSlug, cartId], cart)
    },
    onError: (err, variables, context) => {
      console.error(err)
      queryClient.setQueryData(
        ["cart", marinaSlug, cartId],
        context.previousCart
      )
      showToast("Failed to remove item from cart", { type: "error" })
    },
  })

  const removeItemFromCart = (itemId) => {
    removeItemMutation.mutate({ itemId })
  }

  /* Clear Cart */
  const createCartMutation = useMutation({
    mutationFn: async () => {
      const { data: cart } = await createCart({
        marinaSlug,
      })

      return cart
    },
    onMutate: async ({ cartId: previousCartId }) => {
      const previousCart = queryClient.getQueryData([
        "cart",
        marinaSlug,
        previousCartId,
      ])
      queryClient.removeQueries(["cart", marinaSlug])
      return { previousCart }
    },
    onSuccess: async (cart) => {
      setCartId(cart.id)
      await queryClient.setQueryData(["cart", marinaSlug, cart.id], cart)
      setPanelState({})
    },
    onError: (error, variables, context) => {
      console.error(error)
      queryClient.setQueryData(
        ["cart", marinaSlug, context.previousCart.id],
        context.previousCart
      )
      showToast("Failed to clear cart", { type: "error" })
    },
  })

  const clearCart = async () => {
    await createCartMutation.mutate({ cartId })
  }

  /* Panel Management */
  const panelIsOpen = ({ id }) => {
    return panelState[id] === PanelState.OPEN
  }
  const closeAllPanels = () => {
    setPanelState((current) =>
      Object.fromEntries(
        Object.keys(current).map((key) => [key, PanelState.CLOSED])
      )
    )
  }
  const togglePanelOpen = ({ id }) => {
    setPanelState((current) => ({
      ...current,
      [id]:
        panelState[id] === PanelState.OPEN
          ? PanelState.CLOSED
          : PanelState.OPEN,
    }))
  }

  const totals = useMemo(() => {
    const { totals } = cartData

    return Object.fromEntries(
      Object.entries(totals).map(([key, value]) => [
        key,
        value === null ? "-.--" : (value / 100).toFixed(2),
      ])
    )
  }, [cartData])

  return {
    id: cartId,
    methods,
    fields,
    addItemToCart,
    removeItemFromCart,
    clearCart,
    updateCart,
    panelIsOpen,
    togglePanelOpen,
    closeAllPanels,
    totals,
    serverContact: cartData.contact,
    soldById: cartData.soldById,
    lineItems: cartData.lineItems,
    loading:
      cartDataLoading ||
      updateCartMutation.isLoading ||
      addItemMutation.isLoading ||
      removeItemMutation.isLoading ||
      updateItemMutation.isLoading,
  }
}

export default useServerCart
