import classNames from "classnames"
import { format, parseISO } from "date-fns"
import PropTypes from "prop-types"
import React from "react"

import Modal from "src/components/Modal"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

const PreservedRateModal = ({
  onClose,
  preservedRates,
  telescopeEnabled,
  telescopeCtaPath,
}) => {
  const renderTelescopeCta = () => {
    return (
      <a href={telescopeCtaPath} target="_blank" rel="noreferrer">
        <i
          className="icon icon-info text-gray-500"
          data-toggle="tooltip"
          data-placement="top"
          title="Occupancy-based dynamic rates are a premium feature of Dockwa Telescope. Inquire to learn more."
        />
      </a>
    )
  }

  const anyPersistedPreservedRates = () => {
    return preservedRates.some(
      (preservedRate) => preservedRate.created_at !== null
    )
  }

  const renderModifiers = (modifiers) => {
    if (modifiers.length === 0) return null

    return (
      <div>
        {modifiers.map((modifier, index) => {
          return (
            <div key={index}>{`${modifier.label}: ${modifier.description} ${
              modifier.price !== undefined
                ? `(${formattedCentsToDollars(modifier.price)})`
                : ""
            }`}</div>
          )
        })}
      </div>
    )
  }

  const renderOccupancyNote = () => {
    if (
      preservedRates.filter(
        (preservedRate) => preservedRate.rate !== preservedRate.base_rate
      ).length
    ) {
      return <p>* Indicates a higher rate based on higher occupancy.</p>
    }
  }

  const renderMinNightsNote = () => {
    const modifiers = preservedRates
      .map((preservedRate) => {
        return preservedRate.modifiers
      })
      .flat()

    if (modifiers.filter((modifier) => !modifier.price_modifier).length) {
      return <p>Note: Totals exclude Minimum Nights modifier calculations.</p>
    }
  }

  const renderFooterNote = () => {
    if (renderOccupancyNote() || renderMinNightsNote()) {
      return (
        <tr>
          <td colSpan="100">
            {renderOccupancyNote()}
            {renderMinNightsNote()}
          </td>
        </tr>
      )
    }
  }

  const renderModalContent = () => {
    return (
      <table className="table-default table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Base Rate</th>
            <th>Dynamic Rate {!telescopeEnabled && renderTelescopeCta()}</th>
            <th>Time frame</th>
            {telescopeEnabled && <th>Occupancy</th>}
            <th>Subtotal</th>
            <th>Modifiers</th>
            <th>Estimated Total</th>
            {anyPersistedPreservedRates() && <th>Preserved On</th>}
          </tr>
        </thead>
        <tbody>
          {preservedRates.map((preservedRate) => {
            return (
              <tr key={preservedRate.night}>
                <td>{format(parseISO(preservedRate.night), "EEE, MMM do")}</td>
                <td>
                  {preservedRate.rate_type === "by_length"
                    ? `${formattedCentsToDollars(preservedRate.base_rate)}/ft`
                    : formattedCentsToDollars(preservedRate.base_rate)}
                </td>
                <td
                  className={classNames({
                    "font-semibold text-teal-700":
                      preservedRate.base_rate !== preservedRate.rate,
                  })}
                >
                  {preservedRate.rate_type === "by_length"
                    ? `${formattedCentsToDollars(preservedRate.rate)}/ft`
                    : formattedCentsToDollars(preservedRate.rate)}
                  {preservedRate.base_rate !== preservedRate.rate && "*"}
                </td>
                <td>{preservedRate.timeframe}</td>
                {telescopeEnabled && (
                  <td>
                    {(parseFloat(preservedRate.occupancy) * 100).toFixed(1)}%
                  </td>
                )}
                <td>{formattedCentsToDollars(preservedRate.subtotal)}</td>
                <td>{renderModifiers(preservedRate.modifiers)}</td>
                <td>{formattedCentsToDollars(preservedRate.total)}</td>
                {anyPersistedPreservedRates() && (
                  <td>
                    {preservedRate.created_at
                      ? format(
                          parseISO(preservedRate.created_at),
                          "MMM do, h:mm a"
                        )
                      : ""}
                  </td>
                )}
              </tr>
            )
          })}
          {renderFooterNote()}
        </tbody>
      </table>
    )
  }

  return (
    <Modal isOpen onClose={onClose} size="extraLarge">
      <Modal.Header title="Nightly Rates" />
      <Modal.Body>{renderModalContent()}</Modal.Body>
      <Modal.Footer onClose={onClose} onlyCancel cancelBtnText="Close" />
    </Modal>
  )
}

PreservedRateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  preservedRates: PropTypes.array.isRequired,
  telescopeEnabled: PropTypes.bool.isRequired,
  telescopeCtaPath: PropTypes.string.isRequired,
}
export default PreservedRateModal
