import Decimal from "decimal.js"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useFormContext } from "react-hook-form"

import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { CheckoutContext } from "../Checkout"
import CustomerSelectionScreen from "../Checkout/PaymentModal/CustomerSelectionScreen"
import { BLUETOOTH_CARD_READER_ID } from "../Checkout/constants"
import { formatCartToTxns, formatServerCartToTxns } from "../Checkout/helpers"
import ConfirmationScreen from "../Checkout/payment_flows/ConfirmationScreen"
import { POSContext } from "../Root"
import { useSelectedCardReader } from "./useSelectedCardReader"
import { useSelectedLayout } from "./useSelectedLayout"
import { useSelectedSoldByUser } from "./useSelectedSoldByUser"

export const PAYMENT_PHASES = {
  customer_selection: "customer_selection",
  detail_collection: "detail_collection",
  confirmation: "confirmation",
  processing: "processing",
}

const usePaymentFlow = ({
  exitPaymentFlow,
  paymentFlow: {
    DetailCollectionScreen,
    ConfirmationScreenDetails,
    ProcessingScreen,
    customerSelection,
    showTipInput,
    validatePaymentMethod,
    processSale,
    customerIdentityRequired,
  },
}) => {
  const showToast = useToast()
  const {
    tipItem,
    serverCartFeatureEnabled,
    cart: { updateCart, serverContact },
  } = useContext(POSContext)
  const { watch } = useFormContext()
  const cart = watch("cart")
  const checkoutNote = watch("note")
  const {
    onSaleCompleted,
    onPaymentCanceled,
    enableBluetoothCardReader,
    enableTips,
    sale: retrySale,
  } = useContext(CheckoutContext)

  const [paymentPhase, setPaymentPhase] = useState(
    PAYMENT_PHASES.customer_selection
  )
  const [paymentMethod, setPaymentMethod] = useState({})
  const [contact, setContact] = useState(serverContact || {})
  const [boat, setBoat] = useState({})
  const [errors, setErrors] = useState()
  const [sendCheckoutEmail, setSendCheckoutEmail] = useState(true)
  const [tip, setTip] = useState("0.00")

  const [selectedLayout] = useSelectedLayout()
  const [selectedSoldByUser] = useSelectedSoldByUser()
  const [selectedCardReader] = useSelectedCardReader()

  const clearErrors = () => setErrors()

  const cancelPaymentRef = useRef(false)

  const cancelPayment = useCallback(() => {
    cancelPaymentRef.current = true
  }, [])

  const usingBluetoothReader = useMemo(
    () => selectedCardReader?.id === BLUETOOTH_CARD_READER_ID,
    [selectedCardReader]
  )

  const allowTipsForPaymentFlow = useMemo(() => {
    if (enableTips) {
      return usingBluetoothReader || showTipInput
    }
    return false
  }, [enableTips, showTipInput, usingBluetoothReader])

  useEffect(() => {
    // ensures tip is reset if switching from bluetooth card reader
    // to another card reader
    if (selectedCardReader?.id !== BLUETOOTH_CARD_READER_ID) {
      setTip("0.00")
    }
  }, [selectedCardReader, setTip])

  const goToNextPhase = async () => {
    setErrors()

    if (paymentPhase === PAYMENT_PHASES.customer_selection) {
      if (
        customerSelection === "required" &&
        contact?.name == null &&
        contact?.email == null
      ) {
        setErrors(["Please select a customer."])
        return
      }

      setPaymentPhase(
        DetailCollectionScreen == null
          ? PAYMENT_PHASES.confirmation
          : PAYMENT_PHASES.detail_collection
      )
    } else if (paymentPhase === PAYMENT_PHASES.detail_collection) {
      const errors = validatePaymentMethod(paymentMethod, contact)

      if (errors) {
        setErrors(errors)
      } else {
        setPaymentPhase(PAYMENT_PHASES.confirmation)
      }
    } else if (paymentPhase === PAYMENT_PHASES.confirmation) {
      window.onbeforeunload = () => true
      setPaymentPhase(PAYMENT_PHASES.processing)

      const { txns, amount } = serverCartFeatureEnabled
        ? formatServerCartToTxns({ cart, tip, tipItem })
        : formatCartToTxns({ cart, tip, tipItem })

      try {
        const response = await processSale(
          {
            saleId: retrySale?.encodedId,
            saleParams: {
              txns,
              manage_id: getCurrentMarinaSlug(),
              sale: {
                contact_id: contact?.id,
              },
              checkout: {
                contact_boat_id: boat?.id,
                point_of_sale_layout_id: selectedLayout?.id,
                sold_by_id: selectedSoldByUser?.id,
                note: checkoutNote,
              },
            },
            amount: retrySale
              ? new Decimal(retrySale.total).negated().toNumber()
              : amount,
            paymentMethod,
            sendCheckoutEmail,
          },
          cancelPaymentRef,
          showToast,
          enableBluetoothCardReader,
          setPaymentMethod
        )

        if (cancelPaymentRef.current) {
          onPaymentCanceled(response.sale, response.checkout)
        } else {
          onSaleCompleted(response.sale, response.checkout)
        }

        window.onbeforeunload = undefined
        exitPaymentFlow()
      } catch ({ message }) {
        setErrors([message])
        setPaymentPhase(PAYMENT_PHASES.confirmation)
      }
    }
  }

  const onCancel = () => {
    switch (paymentPhase) {
      case PAYMENT_PHASES.customer_selection:
        exitPaymentFlow()
        break
      case PAYMENT_PHASES.detail_collection:
        setPaymentPhase(PAYMENT_PHASES.customer_selection)
        break
      case PAYMENT_PHASES.confirmation:
        setTip("0.00")
        if (DetailCollectionScreen != null) {
          setPaymentPhase(PAYMENT_PHASES.detail_collection)
        } else if (customerSelection) {
          setPaymentPhase(PAYMENT_PHASES.customer_selection)
        } else {
          exitPaymentFlow()
        }
        break
    }
  }

  const { title, body, cancelButtonLabel, confirmButtonLabel, hideFooter } =
    useMemo(() => {
      switch (paymentPhase) {
        case PAYMENT_PHASES.customer_selection:
          return {
            title: `Select Customer${
              customerSelection === "optional" ? " (optional)" : ""
            }`,
            body: <CustomerSelectionScreen />,
            confirmButtonLabel:
              customerSelection === "optional" &&
              contact?.name == null &&
              contact?.email == null
                ? "Skip"
                : "Continue",
            cancelButtonLabel: "Cancel",
            hideFooter: false,
          }
        case PAYMENT_PHASES.detail_collection:
          return {
            title: DetailCollectionScreen.modalTitle,
            body: <DetailCollectionScreen />,
            confirmButtonLabel: DetailCollectionScreen.confirmButtonLabel,
            cancelButtonLabel: customerSelection ? "Back" : "Cancel",
            hideFooter: false,
          }
        case PAYMENT_PHASES.confirmation:
          return {
            title: "Confirm Sale",
            body: (
              <ConfirmationScreen details={<ConfirmationScreenDetails />} />
            ),
            confirmButtonLabel: ConfirmationScreenDetails.confirmButtonLabel,
            cancelButtonLabel:
              customerSelection || DetailCollectionScreen ? "Back" : "Cancel",
            hideFooter: false,
          }
        case PAYMENT_PHASES.processing:
          return {
            title: ProcessingScreen?.modalTitle ?? "Confirm Sale",
            body: ProcessingScreen ? (
              <ProcessingScreen cancelPayment={cancelPayment} />
            ) : (
              <div className="relative after:absolute after:inset-0 after:bg-white after:opacity-50 after:content-['']">
                <ConfirmationScreen details={<ConfirmationScreenDetails />} />
              </div>
            ),
            confirmButtonLabel: "Processing...",
            hideFooter: ProcessingScreen != null,
          }
      }
    }, [
      ConfirmationScreenDetails.confirmButtonLabel,
      DetailCollectionScreen,
      ProcessingScreen,
      cancelPayment,
      contact?.name,
      customerSelection,
      paymentPhase,
    ])

  const serverSetContact = useCallback(
    (contact) => {
      updateCart({ contactId: contact?.id ? contact.id : null })
      setContact(contact)
    },
    [updateCart]
  )

  const setContactFunction = serverCartFeatureEnabled
    ? serverSetContact
    : setContact

  return {
    checkoutDetails: {
      contact,
      setContact: setContactFunction,
      boat,
      setBoat,
      paymentMethod,
      setPaymentMethod,
      sendCheckoutEmail,
      setSendCheckoutEmail,
      tip,
      setTip,
    },
    flowDetails: {
      paymentPhase,
      goToNextPhase,
      errors,
      clearErrors,
      onCancel,
      title,
      body,
      cancelButtonLabel,
      confirmButtonLabel,
      allowTipsForPaymentFlow,
      hideFooter,
      customerIdentityRequired,
    },
  }
}

export default usePaymentFlow
