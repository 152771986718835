import PropTypes from "prop-types"
import React, { useContext } from "react"
import ServerCartItem from "src/main/PointOfSale/CurrentSalePanel/ServerCartItem"

import CartItem from "../CurrentSalePanel/CartItem"
import SaleNote from "../CurrentSalePanel/SaleNote"
import SoldBySelector from "../CurrentSalePanel/SoldBySelector"
import { POSContext } from "../Root"
import SmallScreenTopNav from "./SmallScreenTopNav"

const SmallScreenCurrentSalePanel = ({ toggleView }) => {
  const {
    cart: { fields },
    serverCartFeatureEnabled,
  } = useContext(POSContext)

  const renderCartItem = (item, index) => {
    return serverCartFeatureEnabled ? (
      <ServerCartItem key={item.id} item={item} index={index} />
    ) : (
      <CartItem key={item.id} item={item} index={index} />
    )
  }

  return (
    <>
      <SmallScreenTopNav onClick={toggleView} headerText="Current Sale">
        <SoldBySelector />
      </SmallScreenTopNav>
      <div className="h-3/4 overflow-y-auto p-4">
        <div className="space-y-2">{fields.map(renderCartItem)}</div>
        <SaleNote />
      </div>
    </>
  )
}

SmallScreenCurrentSalePanel.propTypes = {
  toggleView: PropTypes.func.isRequired,
}

export default SmallScreenCurrentSalePanel
