import { useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"

import { useToast } from "src/hooks/use_toast"
import useWindowSize from "src/hooks/use_window_size"

import { calcCartTotals } from "../CurrentSalePanel/helpers"
import { formatItemForCart } from "../utils"

const useCart = () => {
  const methods = useForm({
    defaultValues: { cart: [], note: "" },
    mode: "onBlur",
  })
  const { fields, append, remove } = useFieldArray({
    name: "cart",
    control: methods.control,
  })
  const showToast = useToast()
  const { isLargeScreen } = useWindowSize()
  const cart = methods.watch("cart")
  const totals = calcCartTotals(cart)

  // panel open/closed logic
  const [closedPanels, setClosedPanels] = useState([])

  const panelIsOpen = ({ id }) => !closedPanels.includes(id)

  const closePanel = (id) => {
    const newClosedPanels = [...closedPanels, id]
    setClosedPanels(newClosedPanels)
  }

  const closeAllPanels = () => {
    const newClosedPanels = fields.map((item) => item.id)
    setClosedPanels(newClosedPanels)
  }

  const removeFromClosedPanels = (id) => {
    const newClosedPanels = closedPanels.filter((panelId) => panelId !== id)
    setClosedPanels(newClosedPanels)
  }

  const togglePanelOpen = ({ id }) => {
    if (panelIsOpen({ id })) {
      closePanel(id)
    } else {
      removeFromClosedPanels(id)
    }
  }

  // adding/removing item from cart
  const addItemToCart = ({ item }) => {
    closeAllPanels()
    const itemToAdd = formatItemForCart(item)
    append(itemToAdd)
    if (!isLargeScreen)
      showToast(`${item.name} Added`, { type: "success", duration: 2 })
  }

  const removeItemFromCart = (item, index) => {
    remove(index)
    removeFromClosedPanels(item.id)
  }

  const clearCart = () => {
    remove()
    methods.resetField("note")
    setClosedPanels([])
  }

  return {
    methods,
    fields,
    addItemToCart,
    removeItemFromCart,
    clearCart,
    panelIsOpen,
    togglePanelOpen,
    closeAllPanels,
    totals,
  }
}

export default useCart
