import { format } from "date-fns"
import Decimal from "decimal.js"
import {
  FIXED_DISCOUNT_TYPE,
  PERCENT_DISCOUNT_TYPE,
} from "src/main/Billing/Items/EditInstallmentStay/EditInstallmentDiscountsModal/helpers"
import {
  dollarsToCents,
  getPriceAndTaxAmounts,
} from "src/main/Billing/Items/helpers"
import { DEFAULT_ONE_TIME_PRICING_STRUCTURE } from "src/main/Billing/constants"

import {
  calcCartTotals,
  itemSubtotalAfterDiscountDollars,
  percentToDecimal,
} from "../CurrentSalePanel/helpers"

export const nameToInitials = (name = "") => {
  if (!name) return ""
  if (name.length === 0) return ""

  return name
    .trim()
    .replace(/[^a-zA-Z ]/, "")
    .split(" ")
    .filter((s) => s.length)
    .map((n) => n[0])
    .map((c) => c.toUpperCase())
    .filter((c, i) => i < 2)
    .join("")
}

export const formatTxns = (item, serviceDate) => {
  const quantity = Number(item.quantity)
  const precision = item.pricePrecision === 4 ? "hundredths_of_cents" : "cents"
  const { originalAmount, taxRate, pricePerUnit } = getPriceAndTaxAmounts(
    {
      txn: {
        product_sale_attributes: {
          price_per_unit: item.price,
          price_precision: precision,
          tax_percent: item.tax,
        },
      },
    },
    quantity
  )
  const subtotalAfterDiscount = dollarsToCents(
    itemSubtotalAfterDiscountDollars(item)
  )
  const discount = Number(dollarsToCents(item?.discountAmount ?? 0))
  const taxAmount = new Decimal(subtotalAfterDiscount).mul(taxRate).toFixed(0)
  const amount = new Decimal(subtotalAfterDiscount).plus(taxAmount).toFixed(0)
  return {
    type: "Billing::ProductSaleTxn",
    amount,
    product_sale_attributes: {
      original_amount: originalAmount,
      tax_amount: taxAmount,
      price_per_unit: pricePerUnit,
      price_precision: precision,
      tax_rate: taxRate,
      pricing_structure: DEFAULT_ONE_TIME_PRICING_STRUCTURE,
      product_id: item.productId,
      service_start_date: serviceDate,
      service_end_date: serviceDate,
      name: item.name,
      note: item.note,
      quantity,
      product_sale_discounts_attributes: discount
        ? [
            {
              type: PERCENT_DISCOUNT_TYPE,
              name: `Point of Sale Discount (${item.name})`,
              apply_order: 0,
              amount: Number(discount),
              percent_amount: percentToDecimal(item.discount),
            },
          ]
        : [],
    },
  }
}

export const formatCartToTxns = ({ cart, tip, tipItem }) => {
  // DEFAULTING DUE DATE TO TODAY FOR POS
  // Defining `serviceDate` once out here so as we iterate we can guarantee
  // all txns will get the same service date
  const serviceDate = format(new Date(), "yyyy-MM-dd")
  const { total } = calcCartTotals(cart)
  const tipPresent = tip && tip !== "" && tip !== "0.00"

  const cartItems = tipPresent ? [...cart, { ...tipItem, price: tip }] : cart
  const totalAsDollars = tipPresent ? Decimal.sum(total, tip).toFixed(2) : total
  const totalAsCents = dollarsToCents(totalAsDollars)

  const txns = cartItems.map((cartItem) => formatTxns(cartItem, serviceDate))
  const amount = new Decimal(totalAsCents).negated().toNumber()
  return { txns, amount }
}

function transformServerCartItemToTxn(cartItem, serviceDate) {
  const {
    quantity,
    pricePrecision: precision,
    subtotal,
    total,
    taxRate: tax,
    discount: manualDiscount,
    taxAmount,
    note,
    name,
    productId,
    price,
    discountTemplate,
  } = cartItem

  const discounts = []
  if (discountTemplate) {
    const { percentAmount, label, amount } = discountTemplate

    if (percentAmount) {
      discounts.push({
        type: PERCENT_DISCOUNT_TYPE,
        name: label,
        amount: Math.round(percentAmount * subtotal),
        percent_amount: percentAmount,
      })
    } else {
      discounts.push({
        type: FIXED_DISCOUNT_TYPE,
        name: label,
        amount: amount * quantity,
      })
    }
  }

  if (manualDiscount) {
    const automatedDiscountAmount = discounts.reduce(
      (acc, discount) => acc + discount.amount,
      0
    )
    const decimalPercent = percentToDecimal(manualDiscount)
    discounts.push({
      type: PERCENT_DISCOUNT_TYPE,
      name: `Point of Sale Discount (${name})`,
      amount: Math.round(decimalPercent * (subtotal - automatedDiscountAmount)),
      percent_amount: decimalPercent,
    })
  }

  const appliedDiscounts = discounts.map((discount, index) => ({
    ...discount,
    apply_order: index,
  }))

  const txn = {
    type: "Billing::ProductSaleTxn",
    amount: total,
    product_sale_attributes: {
      tax_amount: taxAmount,
      original_amount: subtotal,
      price_per_unit: price,
      price_precision: precision,
      tax_rate: tax,
      pricing_structure: DEFAULT_ONE_TIME_PRICING_STRUCTURE,
      product_id: productId,
      service_start_date: serviceDate,
      service_end_date: serviceDate,
      name,
      note,
      quantity,
      product_sale_discounts_attributes: appliedDiscounts,
    },
  }

  return txn
}

export const formatServerCartToTxns = ({ cart, tip, tipItem }) => {
  // DEFAULTING DUE DATE TO TODAY FOR POS
  // Defining `serviceDate` once out here so as we iterate we can guarantee
  // all txns will get the same service date
  const serviceDate = format(new Date(), "yyyy-MM-dd")

  const tipPresent = Boolean(tip) && tip !== "0.00"
  const cartItems = [...cart]

  if (tipPresent) {
    const tipAsCents = dollarsToCents(tip)
    const serverTipItem = {
      discount: 0,
      discountAmount: 0,
      name: "Tip",
      price: tipAsCents,
      pricePrecision: "cents",
      productId: tipItem.productId,
      quantity: 1,
      taxRate: 0,
      taxAmount: 0,
      subtotal: tipAsCents,
      total: tipAsCents,
    }

    cartItems.push(serverTipItem)
  }

  const txns = cartItems.map((cartItem) =>
    transformServerCartItemToTxn(cartItem, serviceDate)
  )

  const amount = new Decimal(
    cartItems.reduce((acc, item) => acc + item.total, 0)
  )
    .negated()
    .toNumber()

  return { txns, amount }
}
