import { mutateApi, queryApi } from "src/utils/api/ApiMethods"

export const createTransientReservation = async ({ marinaSlug, params }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/scheduled_reservations`,
    method: "POST",
    data: params,
  })
}

export const calculatePrice = async ({ marinaSlug, params }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/scheduled_reservations/calculate_price`,
    method: "POST",
    data: params,
  })
}

// Note: 'get' is misleading here, as this is a POST request.
// But, POSTing to this endpoint does not create any resources; it is functionally a GET.
// A good future task would be to update this endpoint to be a GET request.
export const getAvailability = async ({ marinaSlug, params }) => {
  return mutateApi({
    url: `/manage/${marinaSlug}/reservations/availability_v2`,
    method: "POST",
    data: params,
  })
}

export const getCouponCodes = ({
  marinaSlug,
  arrival,
  departure,
  contactId = null,
}) => {
  const queryParams = new URLSearchParams({ contact_id: contactId })

  if (arrival) {
    queryParams.append("arrival", arrival)
  }
  if (departure) {
    queryParams.append("departure", departure)
  }

  return queryApi(
    `/manage/${marinaSlug}/reservations/discounts?${queryParams.toString()}`
  )
}
