import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Outlet, useSearchParams } from "react-router-dom"

export default function Layout({ serverCartFeatureEnabled, serverCartId }) {
  const [, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (serverCartFeatureEnabled) {
      setSearchParams({ cart_id: serverCartId })
    }
  }, [serverCartFeatureEnabled, serverCartId, setSearchParams])

  return <Outlet />
}

Layout.propTypes = {
  serverCartId: PropTypes.string,
  serverCartFeatureEnabled: PropTypes.bool.isRequired,
}
